var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("BaseInput", {
          staticClass: "p-takeover__input",
          attrs: {
            label: "引継コード",
            placeholder: "1234-5678-9012",
            fluid: "",
          },
          model: {
            value: _vm.code,
            callback: function ($$v) {
              _vm.code = $$v
            },
            expression: "code",
          },
        }),
        _c(
          "Button",
          {
            attrs: {
              "style-type":
                !_vm.valid || _vm.$_loading_state ? "disabled" : "secondary",
              fluid: "",
              disabled: !_vm.valid || _vm.$_loading_state,
            },
            on: { click: _vm.sendCode },
          },
          [_vm._v(" 引継コードを送信する ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section c-section--mb32" }, [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v("機種変更をした方へ"),
      ]),
      _c("p", [
        _vm._v(
          " 機種変更前のアカウントを引き継ぎたい方は、機種変更前の端末でコピーした引継コードを以下に入力してください。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }