<template>
  <div>
    <div class="c-section c-section--mb32">
      <h1 class="c-title2 c-title2--mb16">機種変更をした方へ</h1>
      <p>
        機種変更前のアカウントを引き継ぎたい方は、機種変更前の端末でコピーした引継コードを以下に入力してください。
      </p>
    </div>

    <div class="c-section">
      <BaseInput
        v-model="code"
        label="引継コード"
        placeholder="1234-5678-9012"
        fluid
        class="p-takeover__input" />
      <Button
        :style-type="!valid || $_loading_state ? 'disabled' : 'secondary'"
        fluid
        :disabled="!valid || $_loading_state"
        @click="sendCode">
        引継コードを送信する
      </Button>
    </div>
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';
import { useNativeConnection } from '@/composables/useNativeConnection';

const { setAccessToken } = useNativeConnection();

export default {
  data: () => ({
    code: ''
  }),

  computed: {
    valid() {
      return !!this.code;
    }
  },

  methods: {
    async sendCode() {
      if (!this.valid) return;

      try {
        this.$_loading_start();

        // 引継実行
        const { data } = await this.$repositories('customer').useTakeoverCode({
          take_over_code: this.code
        });

        // 認証トークンの更新
        await setAccessToken(data);

        window.location.href = this.$systemDialog(
          SystemDialogMessage.TAKEOVER_SUCCESS
        );
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.TAKEOVER_ERROR
        );
        this.$_loading_stop();
      }
    }
  }
};
</script>
